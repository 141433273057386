.vignette {
  position: relative;
}

.vignette .failing {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: rgba(255, 0, 0, 0.5);
  border: 1px solid #f00;
  color: #fff;
}
.vignette .failing i {
  font-size: 48px;
}
.vignette .failing p {
  margin: 0;
  margin-top: 1em;
  font-size: 0.8em;
  max-height: 70%;
  overflow: hidden;
}

.vignette canvas {
  outline: 1px solid #000;
}
.vignette.hover canvas {
  outline-color: #fc6;
}

.vignette .cursor {
  display: none;
  position: absolute;
  top: 0;
  height: 100%;
  width: 1px;
  background: #fc6;
  pointer-events: none;
}

.vignette.hover .cursor {
  display: block;
}
.vignette footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 2px 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: none;
  display: flex;
  flex-direction: row;
}

.vignette footer .TransitionAuthorAndName {
  flex: 1;
}
