
.Intro {
  color: #aaa;
  font-size: 1.4em;
  padding: 10vw 0 40vh 0;
}

.Intro section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
}

.Intro header, .Intro footer {
  text-align: center;
}

.Intro > header {
  padding: 1em 0;
}

.Intro section:not(.full-width) > * {
  max-width: 512px;
  box-sizing: content-box;
  padding: 20px 40px;
}

.Intro strong {
  color: #fff;
}

.Intro .btn {
  align-self: center;
  background: #fc6;
  color: #000;
  padding: 0.3em 1em;
  margin: 1em 0;
  border-radius: 0.1em;
}
.Intro .btn:hover {
  background: rgb(253, 246, 227);
  color: #000;
}

.Intro code {
  background: #222;
  padding: 0.2em 0.4em;
  white-space: nowrap;
}

.Intro img.full {
  border: 1px solid #000;
  width: 100%;
}

.Intro .vignette footer {
  pointer-events: all;
  bottom: -30px;
  text-align: center;
  font-size: 12px;
  justify-content: center;
}
.Intro .vignette .TransitionAuthorAndName {
  max-width: 100%;
}
.Intro section.full-width .vignette footer {
  font-size: 20px;
  bottom: -40px;
}

.Intro a.small {
  font-size: 0.6em;
}

.Intro a {
  color: #ccc;
}
.Intro a:hover {
  color: #fff;
}

@media screen and (max-width: 540px) {
  .Intro {
    font-size: 1em;
  }
  .Intro header {
    font-size: 1.4em;
  }
  .Intro section > * {
    padding: 20px 0;
  }
  .Intro section p {
    padding: 0 20px;
  }
}
