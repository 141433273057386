body {
  margin: 0;
  padding: 0;
  font-family: Catamaran, sans-serif;
  background: #333;
  color: #eee;
  font-size: 14px;
}
* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 1.6em;
}
h2, h3, h4, h5, h6 {
  font-size: 1.2em;
}

code,
.hljs,
.glsl-editor.ace_editor,
.glsl-documentation .glsl-token-name,
.editor-status-bar,
.compilation-stats-time .value,
.compilation-stats-time .unit {
  font-family: Monoid, 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
}

a {
  color: inherit;
  text-decoration: none;
}
a:active, a:hover {
  color: inherit;
}

blockquote {
  border-left: 2px solid #fc6;
  background: #222;
  color: #fff;
  padding: 10px;
}
