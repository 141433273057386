.SuggestTransform {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.SuggestTransform header {
  text-align: center;
  font-size: 1.2em;
  padding: 2em 4em;
}

.SuggestTransform .container {
  background-color: #333;
  width: 90%;
  height: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.SuggestTransform .body {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.SuggestTransform .body > * {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 10px;
}
.SuggestTransform .body .GlslCode {
  flex: 1;
  width: 100%;
}

.SuggestTransform .button {
  font-size: 1.2em;
  margin: 0.6em;
  padding: 0.2em 1em;
  border: none;
  color: #000;
  background: #fff;
}
