.glsl-editor {
  width: 100%;
  height: 100%;
  flex: 1;
}

.glsl-editor .ace_step.error {
  background: rgba(255, 0, 0, 0.3) !important;
}
.glsl-editor .ace_step.warn {
  background: rgba(255, 100, 0, 0.3) !important;
}
