.TransitionAuthorAndName {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0.4em;
}

.TransitionAuthorAndName em {
  font-style: italic;
  font-weight: lighter;
  font-size: 0.8em;
}
.TransitionAuthorAndName em:before {
  content: "by ";
  opacity: 0.6;
}
