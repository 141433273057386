.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App > header {
  background: #111;
  display: flex;
  flex-direction: row;
  height: 40px;
  padding: 0 0.8em;
  overflow: hidden;
}

.App > header .logo {
  margin-right: 0.8em;
}

.App .logo {
  align-self: center;
  font-weight: bold;
  padding: 0 0.2em;
  font-size: 1.6em;
}

.App .logo span:first-child {
  color: #fff;
}
.App .logo span:last-child {
  color: #fc6;
}

.App > header > nav {
  display: flex;
  flex-direction: row;
}
.App > header > nav > a {
  padding: 0.6em;
  color: #ccc;
}
.App > header > nav > a:hover {
  color: #fff;
}
.App > header > nav > a.active {
  background: #444;
  color: #fff;
}

.App > header > .external {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.App > header > .external > a {
  padding: 0.8em;
  color: #ccc;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

@media screen and (max-width: 540px) {
  .App > header > .external > a > span {
    display: none;
  }
}
@media screen and (max-width: 360px) {
  .App > header .logo .full {
    display: none;
  }
}

.App > main {
  background: #333;
  flex: 1;
  display: flex;
  flex-direction: column;
}
