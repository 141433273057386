.gallery .transitions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px 0;
  margin: 0 auto;
  max-width: 1280px;
}

.gallery .transitions > * {
  margin: 10px;
}
.gallery .transitions .vignette footer .dateago {
  position: absolute;
  right: 4px;
  bottom: 0px;
  font-size: 1em;
  font-style: italic;
  color: #000;
}
.gallery .vignette .expand {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 4px;
}

.gallery .pager {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.gallery .pager a {
  color: #999;
  font-size: 2em;
  padding: 0.2em 0.6em;
}
.gallery .pager a:hover:not(.active) {
  color: #bbb;
}
.gallery .pager a.active {
  color: #fff;
  font-weight: bold;
}
