.Editor {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}
.Editor-body {
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative;
  overflow: auto;
}
.Editor .leftpanel {
  position: relative;
  z-index: 100;
  width: 376px;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.Editor-body .leftpanel .stats {
  position: absolute;
  left: 4px;
}
.Editor .leftpanel .section {
  flex: 1;
}
.Editor .main {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 0;
}
.Editor .editor-action {
  position: absolute;
  top: 15px;
  right: 8px;
  z-index: 10;
}

.Editor .no-uniforms {
  margin: 0 0 0 100px;
  text-align: center;
}

.Editor  .links {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.Editor .links a {
  margin: 2px 0;
  display: inline-block;
  font-size: 12px;
}

.Editor .links  a i {
  margin-right: 4px;
}

.Editor .tname {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-width: 256px;
  margin: 0.5em 0;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Editor .tname .license {
  margin-right: 20px;
  opacity: 0.5;
  font-weight: lighter;
  letter-spacing: 0.1em;
}
.Editor .transition-name {
  width: 230px;
  height: 28px;
  padding: 0 0.4em;
  font-size: inherit;
  text-align: right;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
}
.Editor .transition-name:focus {
  outline: none;
  color: #0F0;
  border-color: #0F0;
}
.Editor .transition-name.error,
.Editor .transition-name.error:focus {
  color: #f00;
  border-color: #f00;
}

.Editor .transition-name-extension {
  width: 26px;
  display: inline-block;
  text-align: right;
}


@media screen and (max-width: 540px) {
  .Editor .main .glsl-editor {
    width: 100vw;
  }
}
